import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Button,
    Divider,
    Grid,
    Menu,
    MenuItem,
    MenuProps,
    Typography,
} from "@mui/material";
import { usePallet } from "../../../../contexts/PalletContext";
import useStyles from "../../../../components/layout/Styles";
import { formatDate } from "../../../../utils/dateUtils";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EditIcon from "@mui/icons-material/Edit";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AlarmIcon from "@mui/icons-material/Alarm";
import styled from "styled-components";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AnexosComponent, { EAnexoModulos } from "../../../../components/Anexos";
import { MoreHoriz } from "@material-ui/icons";
import { INotaTecnica } from "../../../../models/Proposicao.Model";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import WarningIcon from "@mui/icons-material/Warning";
import NotaTecnicaModal from "./NotaTecnicaModal";
import { IAcaoPreparatoria } from "../../../../models/Proposicao.Model";
import ButtonDialogConfirmation from "../../../../components/ButtonDialogConfirmation";
import { useAlert } from "../../../../contexts/AlertContext";

import DeleteIcon from "@mui/icons-material/Delete";
import ProposicaoAcaoPreparatoriaNotaTecnicaService from "../../../../services/ProposicaoAcaoPreparatoriaNotaTecnicaService";
import CircularLoading from "../../../../components/CircularLoading";
import { useAuthorization } from "../../../../contexts/AuthorizationContext";
import { PreText } from "../../../../components/InfoCard";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        minWidth: 135,
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "5px 0px",
            minWidth: 140,
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                // fontSize: 18,
            },
        },
    },
}));

const DotStyle = styled.div`
  background-color: ${(props) => props.bg};
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: 9px;
  margin-top: 3px;
`;

const AnexoGrid = styled(Grid)`
  .upload-message {
    display: none;
  }
`;

const DivAcordion = styled(AccordionDetails)`
  div.MuiAccordionDetails-root {
    padding: 0px 0px 0px 0 px;
  }
`;

export const AccordionNotaTecnica = (props: {
    item: INotaTecnica;
    acao: IAcaoPreparatoria;
    onChange: (data: any) => void;
    reload?: () => void;
}) => {
    const { pallet } = usePallet();
    const classes = useStyles();
    const { NewAlert } = useAlert();

    const deletarNota = () => {
        ProposicaoAcaoPreparatoriaNotaTecnicaService.Delete(props.item.id).then(
            (res) => {
                if (res) {
                    NewAlert("success", "Nota técnica apagada com sucesso");
                    handleClose();
                } else {
                    NewAlert("error", "Tente novamente");
                }
            }
        );
        // .then(x => props.reloadLista())
    };

    const prioridadeColor = (value: string) => {
        switch (value) {
            case "Máxima":
                return `${pallet.charts.red}`;
            case "Baixa":
                return `${pallet.charts.blue}`;
            case "Média":
                return `${pallet.charts.yellow}`;
            case "Alta":
                return `${pallet.charts.purple}`;
            case "Mínima":
                return `${pallet.charts.green}`;
            default:
                return `${pallet.charts.lightGrey}`;
        }
    };


    const posicionamentoColor = (value: string) => {
        switch (value) {
            case "Contrário":
                return `${pallet.charts.red}`;
            case "Contrário com emenda saneadora":
                return `${pallet.charts.orange}`;
            case "Neutro":
                return `${pallet.charts.purple}`;
            case "Favorável com emenda saneadora":
                return `${pallet.charts.blue}`;
            case "Favorável":
                return `${pallet.charts.green}`;
            case "Pendente":
                return `${pallet.charts.yellow}`;
            case "Indefinido":
                return `${pallet.charts.lightGrey}`;
            case "Favorável ao parecer do relator":
                return '#C71585';
            case "Favorável ao parecer do relator com emenda":
                return '#B8860B';
            case "Contrário ao parecer do relator":
                return '#facb74';
            case "Contrário ao parecer do relator com emenda":
                return '#000000';
            default:
                return `${pallet.charts.lightGrey}`;
        }
    };

    const impactoFinanceiroColor = (value: string) => {
        switch (value) {
            case "Indiferente":
                return `${pallet.charts.lightGrey}`;
            case "Positivo":
                return `${pallet.charts.green}`;
            case "Negativo":
                return `${pallet.charts.red}`;
            case "Não Mensurável":
                return `${pallet.charts.purple}`;
            default:
                return `${pallet.charts.yellow}`;
        }
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState();
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        ChangeStatusAnexo()
    }, []);

    const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
        setOpen(id);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(undefined);
    };

    const ChangeStatusAnexo = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 500);
    }

    const AcaoSituacaoTypography = (props: { children: any; style?: any }) => {
        const { pallet } = usePallet();

        return (
            <Typography
                align="justify"
                style={{
                    padding: `${isMobile ? "5px" : "7px"}`,
                    borderRadius: "13px",
                    color: `${pallet.general.color2}`,
                    backgroundColor: `${pallet.backgroundColorQuintenary}`,
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontWeight: pallet.fontWeight.subtitle,
                    fontSize: `${isMobile ? pallet.general.size10 : pallet.general.size12}`,
                    textTransform: "uppercase",
                    ...props?.style,
                }}
            >
                {props.children}
            </Typography>
        );
    };
    const { user } = useAuthorization();
    return (
        <Grid>
            <Grid container style={{ position: "relative" }}>
                {(props.item.status === "Preenchido" && user.id_permissao !== 4 && props.acao.status !== "Concluída") || (props.item.status === "Preenchido" && user.id_permissao === 1 && props.acao.status === "Concluída") || (props.acao.status === "Concluída" && user.id === props.item.id_responsavel) ? (
                    <NotaTecnicaModal
                        responsaveis={[]}
                        acao_preparatoria={props.acao}
                        nota_tecnica={props.item}
                        editLayout={true}
                        onChange={props.onChange}
                        reload={() => {
                            props?.reload()
                            ChangeStatusAnexo()
                        }}
                    >
                        <Button
                            disableElevation
                            variant="contained"
                            size="medium"
                            style={{
                                backgroundColor: `${pallet.backgroundColorTertiary}`,
                                fontFamily: `${pallet.fontFamily.general}`,
                                fontWeight: pallet.fontWeight.lighter,
                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                color: `${pallet.color.quaternary.font}`,
                                borderRadius: "6px",
                                width: "25px",
                                position: "absolute",
                                top: `${isMobile ? "0px" : "15px"}`,
                                height: "20px",
                                right: "5px",
                                zIndex: 10,
                            }}
                            // onClick={(e) => handleClick(e, props.item.id)}
                            endIcon={
                                <Grid
                                    style={{
                                        fontSize: "30px",
                                        marginTop: "9px",
                                        marginRight: "10px",
                                    }}
                                >
                                    <EditIcon fontSize={isMobile ? "small" : "inherit"}   />
                                </Grid>
                            }
                        ></Button>
                    </NotaTecnicaModal>
                ) : (
                    ""
                )}
            </Grid>
            <Grid>
                <Accordion
                    elevation={0}
                    defaultExpanded={props.item.status === "Preenchido" ? false : true}
                    style={{ marginBottom: "10px", border: "1px solid #EAEAEA" }}
                >
                    <AccordionSummary
                        expandIcon={
                            <ArrowDropDownIcon
                                style={{ color: `${pallet.general.color1}` }}
                            />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{
                            backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                            height: "10px",
                            borderRadius: "5px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            marginRight: "auto",
                            flexFlow: "row-reverse",
                        }}
                    >
                        <Typography
                            className={classes.heading}
                            style={{
                                color: `${pallet.textColorTertiary}`,
                                fontFamily: `${pallet.general.fontFamily}`,
                                fontSize: `${pallet.general.size14}`,
                                fontWeight: pallet.general.weight_600,
                                marginLeft: "10px",
                            }}
                        >
                            <Grid
                                container
                                item
                                style={{ alignItems: "center", alignContent: "center" }}
                            >
                                <Grid item style={{ wordBreak: "break-all" }}>
                                    {props.item.responsavel}
                                </Grid>
                                <Grid
                                    item
                                    style={{ marginLeft: `${isMobile ? "0.7px" : "25px"}` }}
                                >
                                    <AcaoSituacaoTypography style={{}}>
                                        {props.item.departamento}
                                    </AcaoSituacaoTypography>
                                </Grid>
                            </Grid>
                        </Typography>
                    </AccordionSummary>

                    <DivAcordion
                        style={{
                            fontSize: pallet.general.size12,
                            fontFamily: `${pallet.general.fontFamily}`,
                        }}
                    >
                        {(props.item.status === "Preenchido") ? (
                            <>
                                <Grid container style={{ padding: "15px" }}>
                                    <Grid container lg={4} item>
                                        <Grid
                                            lg={12}
                                            item
                                            style={{
                                                fontWeight: pallet.general.weight_600,
                                            }}
                                        >
                                            RECEBIDO EM
                                        </Grid>
                                        <Grid
                                            lg={12}
                                            style={{
                                                display: "flex",
                                                alignContent: "center",
                                                alignItems: "center",
                                            }}
                                            item
                                        >
                                            <CalendarTodayIcon />{" "}
                                            <Grid style={{ marginLeft: "10px" }}>
                                                {" "}
                                                {formatDate(
                                                    new Date(props.item.recebido_em),
                                                    "dd/MM/yyyy"
                                                )}
                                            </Grid>
                                            <Grid style={{ marginLeft: "15px" }}>
                                                <AlarmIcon />
                                            </Grid>
                                            <Grid style={{ marginLeft: "10px" }}>
                                                {formatDate(new Date(props.item.recebido_em), "hh")}h
                                                {formatDate(new Date(props.item.recebido_em), "mm")}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        style={{ marginTop: isMobile ? "20px" : "0px" }}
                                        lg={4}
                                        item
                                    >
                                        <Grid
                                            lg={12}
                                            xs={12}
                                            item
                                            style={{
                                                fontWeight: pallet.general.weight_600,
                                            }}
                                        >
                                            PRIORIDADE
                                        </Grid>
                                        <Grid
                                            lg={12}
                                            xs={12}
                                            style={{
                                                display: "flex",
                                                alignContent: "center",
                                                alignItems: "center",
                                                border: "1px solid white",
                                            }}
                                            item
                                        >
                                            <DotStyle
                                                bg={prioridadeColor(props.item.prioridade)}
                                            ></DotStyle>
                                            <Grid>{props.item.prioridade}</Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        style={{ marginTop: isMobile ? "20px" : "0px" }}
                                        lg={4}
                                        item
                                    >
                                        <Grid
                                            lg={12}
                                            xs={12}
                                            item
                                            style={{
                                                fontWeight: pallet.general.weight_600,
                                            }}
                                        >
                                            POSICIONAMENTO
                                        </Grid>
                                        <Grid
                                            lg={12}
                                            xs={12}
                                            style={{
                                                display: "flex",
                                                alignContent: "center",
                                                alignItems: "center",
                                                border: "1px solid white",
                                            }}
                                            item
                                        >
                                            <DotStyle
                                                bg={posicionamentoColor(props.item.posicionamento)}
                                            ></DotStyle>
                                            <Grid>{props.item.posicionamento}</Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ marginTop: "20px" }} lg={4} item>
                                        <Grid
                                            lg={12}
                                            xs={12}
                                            item
                                            style={{
                                                fontWeight: pallet.general.weight_600,
                                            }}
                                        >
                                            IMPACTO FINANCEIRO
                                        </Grid>
                                        <Grid
                                            lg={12}
                                            xs={12}
                                            style={{
                                                marginTop: "5px",
                                                display: "flex",
                                                alignContent: "center",
                                                alignItems: "center",
                                            }}
                                            item
                                        >
                                            <Grid style={{ marginBottom: "3px" }}>
                                                <DotStyle
                                                    bg={impactoFinanceiroColor(props.item.tipo_impacto)}
                                                ></DotStyle>
                                            </Grid>
                                            <Grid>{props.item.tipo_impacto}</Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid container style={{ marginTop: "20px" }} lg={4} item>
                                        <Grid
                                            lg={12}
                                            xs={12}
                                            item
                                            style={{
                                                fontWeight: pallet.general.weight_600,
                                            }}
                                        >
                                            VALOR IMPACTO
                                        </Grid>
                                        <Grid
                                            lg={12}
                                            xs={12}
                                            style={{
                                                marginTop: "5px",
                                                display: "flex",
                                                alignContent: "center",
                                                alignItems: "center",
                                            }}
                                            item
                                        >
                                            <Grid style={{ marginTop: "5px" }}>
                                                {props.item.tipo_impacto === "Positivo" ? (
                                                    <AddCircleIcon
                                                        style={{
                                                            color: impactoFinanceiroColor(
                                                                props.item.tipo_impacto
                                                            ),
                                                        }}
                                                    />
                                                ) : (
                                                    <RemoveCircleIcon
                                                        style={{
                                                            color: impactoFinanceiroColor(
                                                                props.item.tipo_impacto
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid style={{ marginLeft: "5px" }}>
                                                R$ {props.item.valor_impacto}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider variant="fullWidth" />
                                <Grid container>
                                    <Grid
                                        style={{
                                            marginTop: "20px",
                                            marginBottom: "20px",
                                            paddingLeft: "15px",
                                        }}
                                        lg={12}
                                        item
                                    >
                                        <Grid
                                            lg={12}
                                            xs={12}
                                            item
                                            style={{
                                                fontWeight: pallet.general.weight_600,
                                            }}
                                        >
                                            ARGUMENTOS PARA JUSTIFICAR POSIÇÃO
                                        </Grid>
                                        <Grid
                                            lg={12}
                                            xs={12}
                                            style={{ marginTop: "7px", wordBreak: "break-word" }}
                                            item
                                        >
                                            <PreText>
                                                {props.item.justificativa
                                                    ? props.item.justificativa
                                                    : "Justificativa não informada."}
                                            </PreText>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider variant="fullWidth" />
                                <Grid container>
                                    <Grid
                                        style={{
                                            marginTop: "20px",
                                            marginBottom: "20px",
                                            paddingLeft: "15px",
                                        }}
                                        lg={12}
                                        item
                                    >
                                        <Grid
                                            lg={12}
                                            xs={12}
                                            item
                                            style={{
                                                fontWeight: pallet.general.weight_600,
                                            }}
                                        >
                                            TEXTO PRODUZIDO PELO MAQUI
                                        </Grid>
                                        <Grid
                                            lg={12}
                                            xs={12}
                                            style={{ marginTop: "7px", wordBreak: "break-word" }}
                                            item
                                        >
                                            <PreText>
                                                {props.item.texto_ia
                                                    ? props.item.texto_ia
                                                    : "Justificativa não informada."}
                                            </PreText>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider variant="fullWidth" />
                                <Grid container>
                                    <Grid
                                        style={{
                                            marginTop: "20px",
                                            marginBottom: "20px",
                                            paddingLeft: "15px",
                                        }}
                                        lg={12}
                                        item
                                    >

                                        {loading ? (
                                            <CircularLoading
                                                size={100}
                                                top={isMobile ? "37%" : "40%"}
                                                left={isMobile ? "6%" : "16%"}
                                                zIndex={2}
                                            />
                                        ) : (
                                            <Grid lg={12} xs={12} style={{ marginTop: "7px" }} item>
                                                {/* <AnexoGrid
                                                    style={{
                                                    marginRight: "50px",
                                                    width: "100%",
                                                    marginLeft: "0 px",
                                                    }}
                                                > */}
                                                <AnexosComponent
                                                    label=""
                                                    id_referencia={props.item.id}
                                                    modulo={EAnexoModulos.NotaTecnica}
                                                    canEdit={false}
                                                    autosave={false}
                                                    isAnexosTitle={"ANEXOS"}
                                                />
                                                {/* </AnexoGrid> */}
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </>
                        ) : user.id_permissao != 4 || (user.id_permissao === 4 && user.id === props.item.id_responsavel) ? (
                            <>
                                <Grid
                                    container
                                    style={{
                                        display: "flex",
                                        color: "red",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Grid
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            alignContent: "center",
                                        }}
                                    >
                                        <WarningIcon />{" "}
                                        <Grid style={{ marginLeft: "13px" }} item>
                                            PENDENTE
                                        </Grid>
                                    </Grid>
                                    <NotaTecnicaModal
                                        responsaveis={[]}
                                        acao_preparatoria={props.acao}
                                        nota_tecnica={props.item}
                                        onChange={props.onChange}
                                        reload={() => {
                                            props?.reload();
                                        }}
                                    >
                                        <Button
                                            style={{
                                                paddingLeft: "20px",
                                                paddingRight: "20px",
                                                color: pallet.general.color1,
                                                backgroundColor: pallet.backgroundColorQuintenary,
                                            }}
                                            startIcon={<AddCircleIcon />}
                                        >
                                            QUALIFICAR NOTA TÉCNICA
                                        </Button>
                                    </NotaTecnicaModal>
                                </Grid>
                            </>
                        )
                            :
                            "Não é possivel resaponder essa solicitação."}
                    </DivAcordion>
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default AccordionNotaTecnica;
