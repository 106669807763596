import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Link,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import useStyles from "../../../../components/layout/Styles";
import { usePallet } from "../../../../contexts/PalletContext";
import { isMobile } from "react-device-detect";
import { Description } from "@material-ui/icons";
import { formatDate } from "../../../../utils/dateUtils";
import styled from "styled-components";
import { useAlert } from "../../../../contexts/AlertContext";
import { useState, useEffect } from "react";
import ParlamentarDiscursoService from "../../../../services/ParlamentarDiscursoService";
import ButtonDialogConfirmation from "../../../../components/ButtonDialogConfirmation";
import ProposicaoService from "../../../../services/ProposicaoService";

const TitleText = styled(Grid)`
  font-family: ${(props) => props.pallet.general.fontFamily};
  font-weight: ${(props) => props.pallet.general.weight_bold};
  font-size: ${(props) => props.pallet.general.size12};
  color: ${(props) => props.pallet.textColorTertiary};
  margin-top: 4px;
`;

function AccordionTextDataApensadasProposicao(props: {
  lista?: Array<any>,
  proposicao_integrada?: boolean,
  id_proposicao: number,
  onChange?: () => void,
  idPermissao?: number
}) {
  const { NewAlert } = useAlert();
  const { pallet } = usePallet();
  const classes = useStyles();
  const [lista, setLista] = useState<Array<any>>(props.lista);
  const [open, setOpen] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const [idApensada, setIdApensada] =useState<number>(0)


  const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
    setOpen(id)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(undefined)
  };

  useEffect(() => {
    setLista(props.lista);
  }, [props.lista]);

  async function loadApensadas(id: number) {
    // console.log("funcao reload", idDemanda)
    props.onChange();
  }
  // const idApensada = Number(props.lista.map(item => item.id))

  const deletarApensada = (id) => {
    ProposicaoService.DeleteApensada(props.id_proposicao, id).then(res => {
      if (res) {
        NewAlert('success', 'Apensada apagada com sucesso')
        handleClose()
      } else {
        NewAlert('error', 'Erro ao apagar apensada, verifique os campos e apague novamente');
      }
    })
      .then(x => loadApensadas(id))
  }

  const ChipDepartamento = styled(Grid)`
    background-color: #e0cdf8;
    text-transform: uppercase;
    border-radius: 100px;
    font-size: 12px;
    color: ${pallet.general.color4};
    height: ${isMobile ? "max-content" : "23px"};
    letter-spacing: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px 8px 16px;
    text-align: ${isMobile ? "center" : "none"};
    width: max-content;
    max-width: ${isMobile ? "220px" : "max-content"};
    font-weight: ${pallet.fontWeight.subtitle};
    font-size: ${pallet.general.size12};
    color: ${pallet.color.tertiary.font};
    margin-bottom: ${isMobile ? "5px" : "0px"};
    margin-top: ${isMobile ? "5px" : "0px"};
  `;

  const AccordionSum = styled(AccordionSummary)`
    border-radius: 5px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 10px;
    padding-top: ${isMobile ? "0px" : "10px"};
    height: ${isMobile ? "140px" : "70px"};
    flex-flow: row-reverse;

    .MuiAccordionSummary-content {
      flex-direction: column;
    }
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 12px 0;
    }
  `;

  const LabelText = styled(Grid)`
    font-weight: ${(props) => props.pallet.general.weight_600};
    font-size: ${(props) => props.pallet.fontSize.text_10};
    color: ${(props) => props.pallet.textColorQuaternary};
  `;

  const StatusDot = styled.div`
    background-color: ${(props) => props.bg};
    border-radius: 50%;
    height: 10px;
    width: 10px;
    /* margin-top: 5px; */
    margin-right: 5px;
  `;

  const posicionamentoColor = (value: string) => {
    switch (value) {
      case "Contrário":
          return `${pallet.charts.red}`;
      case "Contrário com emenda saneadora":
          return `${pallet.charts.orange}`;
      case "Neutro":
          return `${pallet.charts.purple}`;
      case "Favorável com emenda saneadora":
          return `${pallet.charts.blue}`;
      case "Favorável":
          return `${pallet.charts.green}`;
      case "Pendente":
          return `${pallet.charts.yellow}`;
      case "Indefinido":
          return `${pallet.charts.lightGrey}`;
      case "Favorável ao parecer do relator":
          return `${pallet.charts.lightYellow}`;
      case "Favorável ao parecer do relator com emenda":
          return `${pallet.charts.lightRed}`;
      case "Contrário ao parecer do relator":
          return `${pallet.charts.lightBlue}`;
      case "Contrário ao parecer do relator com emenda":
          return `${pallet.charts.lightsLateGrey}`;
      default:
          return `${pallet.charts.lightGrey}`;
    }
  };

  const prioridadeColor = (value: string) => {
    switch (value) {
      case "Máxima":
        return `${pallet.charts.red}`;
      case "Baixa":
        return `${pallet.charts.blue}`;
      case "Média":
        return `${pallet.charts.yellow}`;
      case "Alta":
        return `${pallet.charts.purple}`;
      case "Mínima":
        return `${pallet.charts.green}`;
      default:
        return `${pallet.charts.lightGrey}`;
    }
  };

  const departamentoColor = (value: string) => {
    switch (value) {
      case "OUTROS":
        return "black";
      case "ASSOCIADA":
        return `${pallet.general.color2}`;
      case "APENSADA":
        return `${pallet.color.secundary}`;
    }
  };

  const tipoRelacao = (value: number) => {
    switch (value) {
      case 1:
        return "APENSADA";
      case 2:
        return "ASSOCIADA";
      case 4:
        return "OUTROS";
    }
  };


  const plural = (value: string) => {
    switch (value) {
      case "OUTROS":
        return "OUTROS";
      case "ASSOCIADA":
        return "ASSOCIADAS";
      case "APENSADA":
        return "APENSADAS";
    }
  };

  // console.log("meu resultado ", lista)
  return (
    <>
      {lista?.map((item) => {
        return (
          <Grid key={item.id} style={{ marginBottom: "20px" }}>
            <Accordion
              expanded={true}
              elevation={0}
              style={{ border: "1px solid #EAEAEA" }}
            >
              <AccordionSum
                style={{
                  paddingLeft: `${isMobile ? "5px" : "20px"}`,
                  alignItems: "baseline",
                  // minInlineSize:`${isMobile ? "max-content" : ""}`,

                }}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid container alignItems={`${isMobile ? "center" : ""}`} direction={`${isMobile ? "column" : "row"}`} style={{ alignItems: `${isMobile ? "center" : "baseline"}`, justifyContent: `${isMobile ? "center" : ""}` }} >
                  <Grid >
                    <ChipDepartamento
                      item
                      style={{
                        backgroundColor: `${departamentoColor(
                          tipoRelacao(item.tipo_relacao)
                        )}`,
                        color:
                          tipoRelacao(item.tipo_relacao) === "APENSADA"
                            ? pallet.general.color2
                            : "white",
                      }}
                    >
                      {tipoRelacao(item.tipo_relacao) === "ASSOCIADA" ? (
                        <Grid>
                          {" "}
                          {tipoRelacao(item.tipo_relacao)} | {item.casa}{" "}
                        </Grid>
                      ) : (
                        tipoRelacao(item.tipo_relacao)
                      )}
                    </ChipDepartamento>
                  </Grid>
                  <Grid>
                    <Description
                      style={{
                        color: `${pallet.backgroundColorPrimary}`,
                        width: "18px",
                        marginBottom: `${isMobile ? "0px" : "-7px"}`,
                        marginRight: "5px",
                        marginLeft: "5px",
                      }}
                    />
                  </Grid>
                  <TitleText
                    className={classes.heading}
                    pallet={pallet}
                    style={{
                      fontWeight: pallet.general.weight_bold,
                      marginTop: "2px",
                    }}
                  >
                    <Link
                      href={`/proposicao/${item.proposicao_id}`}
                      style={{
                        color: `${pallet.color.secundary.font}`,
                        fontSize: `${pallet.fontSize.titleWidgets}`,
                        fontFamily: `${pallet.fontFamily.general}`,
                        // fontWeight: pallet.general.weight_600,
                        textDecoration: "none",
                      }}
                    >
                      {item.descricao}
                    </Link>
                  </TitleText>

                  <TitleText
                    pallet={pallet}
                    style={{
                      fontWeight: pallet.general.weight_600,
                      // marginTop: "4px",
                      // marginLeft: "10px",
                    }}
                  >
                 
                    {item.apelido === null ? "" : item.apelido}
                  </TitleText>

                  {props?.proposicao_integrada === false ?
                    <Grid style={{
                      backgroundColor: `${pallet.backgroundColorSecondary}`,
                      marginInlineStart: `${isMobile ? "unset" : "auto"}`,
                    }}
                    >
                      <ButtonDialogConfirmation
                        size="small"
                        styles={{
                          fontFamily: `${pallet.fontFamily.general}`,
                          fontWeight: pallet.fontWeight.button,
                          fontSize: `${pallet.general.size12}`,
                          color: `${pallet.color.quaternary.font}`,
                          borderRadius: "6px 6px 0px 0px",
                          textTransform: "capitalize",
                          backgroundColor: `${pallet.backgroundColorSecondary}`,
                          height: "20px",
                          // paddingBottom: `${isMobile ? "100px" : "0px"}`,
                          // paddingRight: `${isMobile ? "10px" : "10px"}`,


                        }}
                        title={"CONFIRMAÇÃO"}
                        title_button={""}
                        confirmation_button={"Apagar"}
                        content={"APAGAR APENSADA?"}
                        message={"Esta ação não poderá ser desfeita."}
                        confirmationEvent={() => props.idPermissao !== 3 ? deletarApensada(item.id) : NewAlert(
                          "error",
                          "Não é possível realizar está ação com este perfil.")}
                        // onClick={(e) => handleClick(e, item.id)}
                        endIcon={
                          <Grid
                            style={{
                              fontSize: "25px",
                              marginTop: `${isMobile ? "0px" : "5px"}`,
                              marginBottom: `${isMobile ? "20px" : "5px"}`,
                              marginRight: `${isMobile ? "12px" : "10px"}`,
                              maxWidth: `${isMobile ? "20px" : ""}`,
                              maxHeight: `${isMobile ? "20px" : ""}`,
                            }}
                          >
                            <DeleteIcon fontSize="inherit" />
                          </Grid>
                        }
                      >
                      </ButtonDialogConfirmation>
                    </Grid>
                    :
                    <span></span>

                  }
                </Grid>
              </AccordionSum>

              <Divider />

              <AccordionDetails
                style={{
                  fontFamily: `${pallet.general.fontFamily}`,
                  background: `${pallet.backGroundPageColorSecondary}`,
                }}
              >
                <Grid
                  style={{
                    padding: `${isMobile ? "20px 5px 10px 5px" : "20px 20px 10px 20px"
                      }`,
                    width: "100%",
                  }}
                >
                  <Grid xl={12}>
                    <Grid
                      xl={12}
                      container
                      justifyContent="space-between"
                      style={{ paddingTop: "10px" }}
                    >
                      {/* <Grid xs={12} lg={3} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                                <LabelText pallet={pallet}>
                                                    APELIDO DA PROPOSIÇÃO
                                                </LabelText>

                                                <Grid style={{ marginTop: "10px" }}>
                                                    {item.proposicao_cliente_apelido === null ? "Não Definido" : item.proposicao_cliente_apelido}
                                                </Grid>
                                            </Grid> */}
                      <Grid
                        xs={12}
                        lg={3}
                        style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}
                      >
                        <LabelText pallet={pallet}>
                          DATA DE APRESENTAÇÃO
                        </LabelText>

                        <Grid style={{ marginTop: "10px" }}>
                          {formatDate(new Date(item.data), "dd/MM/yyyy")}
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        lg={3}
                        style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}
                      >
                        <LabelText pallet={pallet}>PRIORIDADE</LabelText>

                        <Grid
                          container
                          style={{ marginTop: "10px", alignItems: "center" }}
                        >
                          <Grid>
                            <StatusDot bg={prioridadeColor(item?.prioridade)} />
                          </Grid>
                          <Grid>{item?.prioridade}</Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        lg={4}
                        style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}
                      >
                        <LabelText pallet={pallet}>POSICIONAMENTO</LabelText>
                        <Grid
                          container
                          style={{ marginTop: "10px", alignItems: "center" }}
                        >
                          <Grid>
                            <StatusDot
                              bg={posicionamentoColor(item?.posicao)}
                            />
                          </Grid>

                          <Grid>{item.posicao}</Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    xl={12}
                    container
                    justifyContent="space-between"
                    style={{ paddingTop: "30px" }}
                  >
                    <Grid
                      xs={12}
                      lg={12}
                      style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}
                    >
                      <LabelText pallet={pallet}>EMENTA</LabelText>

                      <Grid style={{ marginTop: "10px", wordBreak: "break-word" }}>{item?.ementa}</Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    xl={12}
                    container
                    justifyContent="space-between"
                    style={{ paddingTop: "30px" }}
                  >
                    <Grid
                      xs={12}
                      lg={12}
                      style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}
                    >
                      <LabelText pallet={pallet}>AUTORE(S)</LabelText>

                      <Grid style={{ marginTop: "10px", wordBreak: "break-word" }}>{item?.autores.map((a) => {
                        return(
                          a.nome
                        )
                      } ).join(',')}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        );
      })}
    </>
  );
}

export default AccordionTextDataApensadasProposicao;

function NewAlert(arg0: string, arg1: string) {
  throw new Error("Function not implemented.");
}
