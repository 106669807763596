import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import PasswordIcon from '@mui/icons-material/Password'
import CloseIcon from "@mui/icons-material/Close";
import { Grid, Divider, ListItemText, Switch } from "@mui/material";
import { usePallet } from "../../../contexts/PalletContext";
import SolidButton from "../../../components/SolidButton";
import { useAlert } from "../../../contexts/AlertContext";
import InputTextField from "../../../components/InputTextField";
import ButtonDialogConfirmation from "../../../components/ButtonDialogConfirmation";
import MenuItem from "@mui/material/MenuItem";
import UsuarioService from "../../../services/UsuarioService";
import TabelasService from "../../../services/TabelasService";
import { IUser } from "../../../models/User.Model";
import UsuarioUpdatePassWordSchema from "./UsuarioUpdatePassWordSchema";
import { useAuthorization } from "../../../contexts/AuthorizationContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};


export default function CustomizedDialogs(props: {
  reloadList?: () => void;
  closeMenu?: () => void;
  usuario: IUser;
  comSeparador?: boolean;
}) {
  const { user } = useAuthorization();
  const { NewAlert } = useAlert();
  const [open, setOpen] = React.useState(false);
  const { pallet } = usePallet();
  const [dataAcontecimentosAgenda, setDataAcontecimentosAgenda] = React.useState();
  const autorizado = user.id_permissao === 1 ||  user.id === props.usuario.id;
  const [nome, setNome] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [usuario, setUsuario] = React.useState("");
  const [senha, setSenha] = React.useState("");
  const [senhaatual, setSenhaatual] = React.useState("");
  const [senhanova, setSenhanova] = React.useState("");
  const [senhanovaconf, setSenhanovaconf] = React.useState("");
  const [permissao, setPermissao] = React.useState(0);
  const [situacao, setSituacao] = React.useState(0);
  const [departamento, setDepartamento] = React.useState("");
  const [listaDepartamento, setListaDepartamento] = React.useState([]);
  const [listaPermissao] = React.useState([
    {
      key: 1,
      label: "Master",
      value: 1
    },
    {
      key: 2,
      label: "Normal",
      value: 2
    },
    {
      key: 3,
      label: "Read Only",
      value: 3
    },
    {
      key: 4,
      label: "Esforço Colaborativo",
      value: 4
    }

  ]);
  const [onSave, setOnSave] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const [idRef, setIdRef] = React.useState();
  const [idAgRef, setIdAgRef] = React.useState<string>();
  const [data, setData] = React.useState({});
  const [horas, setHoras] = React.useState<string>();
  const [local, setLocal] = React.useState<string>();
  const [error, setError] = React.useState<
    Array<{ name: string; err: string }>
  >([]);
  const [imageGet, setImageGet] = React.useState("");
  const [imageSelected, setImageSelected] = React.useState({});


  const handleDataAcontecimentosAgendaChange = (dataAcEve) => {
    setDataAcontecimentosAgenda(dataAcEve);
  };
  React.useEffect(() => {

    TabelasService.getTabelaDepartamentos().then(async (res) => {
      const data = [];
      data.push({ key: "", label: "-- Selecione -- ", value: "" });
      res?.map((item) =>
        data.push({
          key: item.descricao,
          label: item.descricao,
          value: item.id,
        })
      );
      setListaDepartamento(() => [...data]);
    });
  }, []);

  const handleDepartamentoOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDepartamento(event.target.value);
  };
  const handlePermissaoOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPermissao(Number(event.target.value));
  };

  // const handleSituacaoOnChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setSituacao(Number(event.target.value));
  // };

  const handleOnChangeStatus = () => {

    // console.log("ffffffffTTarget...",event.target)
    // setStatus(!status)

    if (status === true) {
      setStatus(false);
      setSituacao(0);
    }
    else {
      setStatus(true);
      setSituacao(1);
    }
  };




  const handleClickOpen = async () => {
    // console.log("INFO",props.usuario.login)
    // console.log("INFO2",props.usuario.id_permissao)
    //console.log("INFO3", props.usuario.arquivo)
    setNome(props.usuario.nome);
    setEmail(props.usuario.email);
    setUsuario(props.usuario.login);
    setImageGet(props.usuario.arquivo)
    // setSenha(props.usuario.password);
    setStatus(props.usuario.status);
    setPermissao(props.usuario.id_permissao);
    setSituacao(Number(props.usuario.situacao));
    setDepartamento(String(props.usuario.dep_id));


    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onImageSelect = (file) => {
    setImageSelected(file);
  };
  const handleChangeNome = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNome(event.target.value);
  };
  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleChangeUsuario = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsuario(event.target.value);
  };
  const handleChangeSenha = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSenha(event.target.value);
  };

  const handleChangeSenhaAtual = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSenhaatual(event.target.value);
  };

  const handleChangeSenhaNova = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSenhanova(event.target.value);
  };

  const handleChangeSenhaNovaConf = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSenhanovaconf(event.target.value);
  };


  const postCadastro = async (e) => {
    e.preventDefault();

    const data = {
      senhaatual: senhaatual,
      senhanova: senhanova,
      senhanovaconf: senhanovaconf,
      id_departamento: props.usuario.dep_id 
    };

    setError(() => []);


    data &&
      UsuarioUpdatePassWordSchema
        .validate(data, { abortEarly: false })
        .catch(err => setError(() => err.errors));

    setData(data);
    UsuarioUpdatePassWordSchema.validate(data).catch(function (err) { });

    //Verifico se senha atual é válida
    


      //Verifico se senha nova é igual a confirmada
      if (senhanova !== senhanovaconf) {
        NewAlert('error', 'Nova senha não é igual a confirmada!');
        //handleClose()
        //props?.closeMenu()
      } else {       
        console.log("VINDO DO PROPS><><><><>",props.usuario)
        await UsuarioService.UpdateGer(Number(props?.usuario.id), data)
          .then(res => {

            setSenhaatual("")
            setSenhanova("")
            setSenhanovaconf("")

            if (res) {

              handleClose()
              props?.closeMenu()
              NewAlert('success', 'Senha atualizada com sucesso')

            } else {
              
              handleClose()
              props?.closeMenu()
              NewAlert('error', 'A senha digitada não é a atual');
              
            }
          })

      }
    

  }

  async function handleOnAfterUpload() {
    if (idRef) {
      setIdRef(undefined);
      setOnSave(false);
    }
  }
  const ErrorMessage = (props: {
    name: string;
    errors: Array<{ name: string; err: string }>;
  }) => {
    const itemError = props.errors.filter((err) => err.name === props.name);
    return (
      <div style={{ color: "red", fontSize: "11px", marginTop: "5px" }}>
        {itemError.length === 1 && itemError[0].err}
      </div>
    );
  };
  //console.log("USUARIO NA MODEL", props.usuario)

  return autorizado ? (
    <MenuItem>
      <div>
        {props.comSeparador ? <Divider style={{marginTop: "0px", marginBottom: "0px"}}/> : <></>}

        <Button onClick={() => { autorizado ?
          handleClickOpen()
          :
          NewAlert(
            "error",
            "Não é possível realizar está ação com este perfil.")
        }}
          style={{
            marginLeft: "-7px",
            fontFamily: `${pallet.fontFamily.general}`,
            fontWeight: pallet.fontWeight.button,
            fontSize: `${pallet.general.size14}`,
            color: `${pallet.color.tertiary.font}`,
            borderRadius: "6px",
            textTransform: "capitalize",
            paddingRight: "85px",
            marginRight: "-10px"
          }}>
          <PasswordIcon style={{
            color: `${pallet.color.tertiary.font}`,
            marginRight: "16px",
            fontFamily: `${pallet.fontFamily.general}`,
          }} />
          <ListItemText>º</ListItemText>
          Alterar Senha
        </Button>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth={"sm"}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <Grid
              style={{
                fontFamily: `${pallet.fontFamily}`,
                fontSize: `${pallet.general.size18}`,
                color: `${pallet.textColorSecondary}`,
                padding: "12px",
                marginTop: "7px",
              }}
            >
              ALTERAR SENHA
            </Grid>
          </BootstrapDialogTitle>

          <DialogContent dividers style={{ padding: "0px" }}>
            <Grid
              container
              style={{
                fontFamily: pallet.fontFamily.general,
                padding: "25px 20px 20px 40px",
              }}
            >
              <Grid
                style={{ marginTop: "20px", padding: "10px" }}
                item
                lg={12}
                xs={12}>
                <Grid>
                  <InputTextField
                    type={"password"}
                    value={senhaatual}
                    name="senhaatual"
                    onChange={handleChangeSenhaAtual}
                    id={"senhaatual"}
                    label={"Senha atual"}
                  />
                </Grid>
                <Grid style={{ color: 'red', fontSize: '10px' }}>
                  <ErrorMessage name='senhaatual' errors={error} />
                </Grid>
              </Grid> <Grid
                style={{ marginTop: "20px", padding: "10px" }}
                item
                lg={12}
                xs={12}>
                <Grid>
                  <InputTextField
                    type={"password"}
                    value={senhanova}
                    name="senhanova"
                    onChange={handleChangeSenhaNova}
                    id={"senhanova"}
                    label={"Nova senha"}
                  />
                </Grid>
                <Grid style={{ color: 'red', fontSize: '10px' }}>
                  <ErrorMessage name='senhanova' errors={error} />
                </Grid>
              </Grid>
              <Grid
                style={{ marginTop: "20px", padding: "10px" }}
                item
                lg={12}
                xs={12}>
                <Grid>
                  <InputTextField
                    type={"password"}
                    value={senhanovaconf}
                    name="senhanovaconf"
                    onChange={handleChangeSenhaNovaConf}
                    id={"senhanovaconf"}
                    label={"Confirmar nova senha"}
                  />
                </Grid>
                <Grid style={{ color: 'red', fontSize: '10px' }}>
                  <ErrorMessage name='senhanovaconf' errors={error} />
                </Grid>
              </Grid>

            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              padding: "30px",
              backgroundColor: `${pallet.backGroundPageColorPrimary}`,
            }}
          >
            <ButtonDialogConfirmation
              styles={{
                borderRadius: "6px 6px 0px 0px",
                marginRight: "20px",
                textTransform: "capitalize",
                color: `${pallet.general.color1}`,
              }}
              title={"CONFIRMAÇÃO"}
              title_button={"Cancelar"}
              content={"CANCELAR CADASTRO?"}
              message={"Esta ação não poderá ser desfeita."}
              confirmation_button={"Cancelar"}
              confirmationEvent={() => {
                handleClose();
              }}
            />
            <SolidButton
              onClick={postCadastro}
              onChange={props?.reloadList}
              color={`${pallet.textColorPrimary}`}
              backgroundColor={`${pallet.backgroundColorPrimary}`}
              fontSize={`${pallet.general.size14}`}
              title={"SALVAR"}
            />
          </DialogActions>
        </BootstrapDialog>
      </div>
    </MenuItem>
  ) : null;
}
