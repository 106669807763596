import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

import styled from "styled-components";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  capitalize,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { usePallet } from "../../../../contexts/PalletContext";
import { useAlert } from "../../../../contexts/AlertContext";

// import AccordionProposicaoListaDetail from './AccordionProposicaoListaDetail';

import ButtonNaoSeguir from "../../../../components/ButtonNaoSeguir";
import ButtonSeguir from "../../../../components/ButtonSeguir";
import useStyles from "../../../../components/layout/Styles";

import ProposicaoService from "../../../../services/ProposicaoService";


const AccordionSum = styled(AccordionSummary)`
  border-radius: 5px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: ${isMobile ? "2px" : "10px"};
  padding-top: ${isMobile ? "2px" : "10px"};
  height: ${isMobile ? "auto" : "68px"};
  flex-flow: row-reverse;
  background-color: ${(props) => props.pallet.backGroundPaperColorPrimary};

  .MuiAccordionSummary-content {
    flex-direction: column;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }
`;


const RightButton = styled(Grid)`
  position: absolute;
  right: 20px;
`;


const NomeProposicao = styled(Grid)`
  // position: absolute;
  color: ${(props) => props.pallet.textColorQuaternary};
  font-family: ${(props) => props.pallet.general.fontFamily};
  font-size: ${(props) => props.pallet.general.size12};
  font-weight: ${(props) => props.pallet.general.weight_600};
  text-transform: uppercase;
  cursor: pointer;
  strong {
    color: ${(props) => props.pallet.textColorTertiary};
  }
`;


const ProposicaoTitle = styled(Typography)`
  color: ${(props) => props.pallet.textColorQuaternary};
  font-family: ${(props) => props.pallet.general.fontFamily};
  font-size: ${(props) => props.pallet.general.size12};
  font-weight: ${(props) => props.pallet.general.weight_600};
  text-transform: uppercase;

  strong {
    color: ${(props) => props.pallet.textColorTertiary};
  }
`;


const ChipStyle = styled(Grid)`
  background-color: ${(props) => props.pallet.backGroundPageColorSecondary};
  text-transform: uppercase;
  border-radius: 100px;
  font-size: 12px;
  border: 1px solid ${(props) => props.pallet.general.color4};
  color: ${(props) => props.pallet.general.color4};
  padding: 4px 11px 4px 11px;
  /* margin-bottom: 7px; */
  line-height: 15px;
  letter-spacing: 1px;
`;

const DotStylePo = styled.div`
  background-color: ${(props) => props.bg};
  border-radius: 50%;
  height: 8px;
  width:${isMobile? `11px;` : `8px;` } 
  margin-right: 9px;
  margin-top: 3px;
`;


const DotStyle = styled.div`
  background-color: ${(props) => props.bg};
  border-radius: 50%;
  height: 8px;
  width: 8px; 
  margin-right: 9px;
  margin-top: 3px;
`;


const TitleGrid = styled(Grid)`
  font-size: ${(props) => props.pallet.general.size14};
  font-weight: ${(props) => props.pallet.general.weight_bold};
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 15px;
`;


const ContainerGrid = styled(Grid)`
  justify-content: space-between;
  width: 100%;
`;


const ContentGrid = styled(Grid)`
  padding: 0 20px 0 0px;
  width: 20%;

  > div:first-of-type {
    font-weight: ${(props) => props.pallet.general.weight_600};
  }
`;


export interface IProposicao {
  descricao: string;
  id: number;
  id_casa: number;
  sigla_casa: string;
  casa_nome: string;
  ementa: string;
  tramitacao: string;
  situacao_descricao: string;
  tipo_descricao: string;
  orgao_sigla: string;
  regime: string;
  autores: [
    {
      id: number;
      nome: string;
    }
  ];
  temas_legislativo: [
    {
      descricao: string;
    }
  ];
  cliente: {
    apelido: string;
    posicionamento: string;
    posicionamento_descricao: string;
    prioridade: string;
    prioridade_descricao: string;
    seguindo: boolean;
    tema_corporativo: {
      subtema_descricao: string;
      tema_descricao: string;
      grupo_descricao: string;
      topico_descricao: string;
    };
  };
};


function AccordionProposicaoLista(props: {
  item?: IProposicao;
  wordWrited?: String;
  onClick?: (String) => void;
  idPermissao?: number;
}): JSX.Element {
  const { pallet } = usePallet();
  const { NewAlert } = useAlert();
  const classes = useStyles();
  const prioridadeColor = (value: string) => {
    switch (value) {
      case "Máxima":
        return `${pallet.charts.red}`;
      case "Alta":
        return `${pallet.charts.purple}`;
      case "Média":
        return `${pallet.charts.yellow}`;
      case "Baixa":
        return `${pallet.charts.blue}`;
      case "Mínima":
        return `${pallet.charts.green}`;
      default:
        return `${pallet.charts.lightGrey}`;
    }
  };

  const posicionamentoColor = (value: string) => {
    switch (value) {
      case "Contrário":
        return `${pallet.charts.red}`;
      case "Contrário com Emenda Saneadora":
        return `${pallet.charts.orange}`;
      case "Neutro":
        return `${pallet.charts.purple}`;
      case "Favorável com Emenda Saneadora":
        return `${pallet.charts.blue}`;
      case "Favorável":
        return `${pallet.charts.green}`;
      case "Pendente":
        return `${pallet.charts.yellow}`;
      case "Indefinido":
        return `${pallet.charts.lightGrey}`;
      case "Favorável ao parecer do relator":
        return '#C71585';
      case "Favorável ao parecer do relator com emenda":
        return '#B8860B';
      case "Contrário ao parecer do relator":
        return '#facb74';
      case "Contrário ao parecer do relator com emenda":
        return '#000000';
      default:
        return `${pallet.charts.lightGrey}`;
    }
  };

  const siglaDescription = (value: string, nome: string) => {
    switch (value) {
      case "SF":
        return "Senado Federal";
      case "CN":
        return "Congresso Nacional";
      case "CD":
        return "Câmara dos Deputados";
      default:
        if (nome) return nome;
        return value;
    }
  };

  const seguirClick = (id: number, value: boolean, path: string = undefined) => {
    ProposicaoService.SetSeguir(id, value, path).then((res) => {
      if (res) {
        if (props.onClick) props.onClick(props.wordWrited);
        NewAlert("success", "Registro Salvo com sucesso");
      } else NewAlert("error", "Ocorreu um erro");
    });
  };

  const navigate = useNavigate();

  const [expandedAcordion, setExpandedAcordion] = useState(true);

  const handleChangeAcordion =
    (ativo: boolean) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAcordion(!ativo);
    };

  function search(e) {
    let splitResult = e?.split(" ");
    let textJoin = splitResult?.map((x) => {
      return (
        <span
          style={{
            backgroundColor: `${x === props.wordWrited ||
              x === props.wordWrited + "." ||
              x === props.wordWrited + "," ||
              x === props.wordWrited + "s" ||
              x === props.wordWrited + "s" + "." ||
              x ===
              capitalize(
                props && props.wordWrited ? props?.wordWrited.toString() : ""
              )
              ? "yellow"
              : "white"
              }`,
          }}
        >
          {x + " "}
        </span>
      );
    });
    return textJoin;
  }

  return (
    <>
      <Grid
        key={0}
        style={{
          marginBottom: "10px",
          position: "relative",
        }}
      >
        <Accordion
          elevation={0}
          expanded={expandedAcordion}
          onChange={handleChangeAcordion(expandedAcordion)}
          style={{
            border: "1px solid #EAEAEA",
            height: "auto",
            padding: "15px 0px",
            maxWidth: `${isMobile ? "344px" : "1153px"}`,
          }}
        >
          <AccordionSum
            pallet={pallet}
            style={{
              paddingLeft: `${isMobile ? "5px" : "20px"}`,
              paddingRight: "10px",
            }}
            expandIcon={
              <ArrowDropDownIcon
                style={{
                  color: `${pallet.general.color2}`,
                  fontSize: `${pallet.general.size16}`,
                }}
              />
            }
            aria-controls="panel1a-content"
            id={`panel1a-header-${props.item.id}`}
          >
            <Grid
              container
              style={{
                marginLeft: "10px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Grid item>
                <DescriptionIcon
                  style={{
                    color: `${pallet.backgroundColorPrimary}`,
                    width: "16px",
                    marginBottom: isMobile ? "0 px" : "-7px",
                    marginRight: "5px",
                  }}
                />
              </Grid>
              <Grid item>
                <a
                  style={{ textDecoration: "none" }}
                  href={"/proposicao/" + props?.item.id}
                >
                  <NomeProposicao
                    // onClick={() =>  window.open('/proposicao/' + props?.item.id, "_blank")}
                    pallet={pallet}
                  >
                    <strong>{props?.item.descricao}</strong>
                  </NomeProposicao>
                </a>
              </Grid>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <Grid style={{ maxWidth: `${isMobile ? "203px" : "100%"}` }}>
                <ProposicaoTitle className={classes.heading} pallet={pallet}>
                  {siglaDescription(
                    props?.item.sigla_casa,
                    props?.item.casa_nome
                  )}
                </ProposicaoTitle>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                paddingTop: isMobile ? "0px" : "5px",
                paddingLeft: "6px",
              }}
            >
              <Grid
                style={{
                  paddingRight: "14px",
                  minWidth: `${isMobile ? "60%" : "1px"}`,
                  maxWidth: `${isMobile ? "65%" : "auto"}`,
                  wordBreak: "break-word",
                }}
              >
                <Typography
                  className={classes.heading}
                  style={{
                    color: `${pallet.textColorTertiary}`,
                    fontFamily: `${pallet.general.fontFamily}`,
                    fontSize: `${pallet.general.size12}`,
                    fontWeight: pallet.general.weight_600,
                    paddingTop: isMobile ? "0px" : "3px",
                    paddingLeft: `${props.item.cliente.apelido ? "9px" : ""}`,
                    wordBreak: "break-word",
                  }}
                >
                  {props?.item.cliente.apelido
                    ? props?.item.cliente.apelido
                    : "Apelido não informado"}
                </Typography>
              </Grid>
              {props.idPermissao !== 4 ? (
                <Grid container style={{marginBottom:"30px"}}>
                  <Grid style={{ marginRight: "14px" }}>
                    <ChipStyle pallet={pallet}>
                      <Grid container>
                        <DotStyle
                          bg={prioridadeColor(
                            props?.item.cliente.prioridade_descricao
                          )}
                        ></DotStyle>
                        <Grid>
                          PRIORIDADE {props?.item.cliente.prioridade_descricao}
                        </Grid>
                      </Grid>
                    </ChipStyle>
                  </Grid>

                  <Grid>
                      {isMobile ?
                      
                      <ChipStyle pallet={pallet}>
                          <Grid style={{display: 'flex',flexDirection:"row"}}>
                          <DotStylePo
                            bg={posicionamentoColor(
                              props?.item.cliente.posicionamento_descricao
                            )}
                          ></DotStylePo>
                          <Grid>
                            POSICIONAMENTO{" "}
                            {props?.item.cliente.posicionamento_descricao}
                          </Grid>
                        </Grid>  
                        </ChipStyle>
                        :
                        <ChipStyle pallet={pallet}>
                        <Grid container>
                        <DotStyle
                          bg={posicionamentoColor(
                            props?.item.cliente.posicionamento_descricao
                          )}
                        ></DotStyle>
                        <Grid>
                          POSICIONAMENTO{" "}
                          {props?.item.cliente.posicionamento_descricao}
                        </Grid>
                      </Grid>  
                      </ChipStyle>
                    }


                      {/* <Grid container> */}

             
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </AccordionSum>
          <Divider style={{ marginTop: "15px" }} />

          <AccordionDetails
            style={{
              fontFamily: `${pallet.general.fontFamily}`,
              padding: "20px 25px 5px",
            }}
          >
            <Grid container style={{ fontSize: "12px" }}>
              <TitleGrid pallet={pallet}>LEGISLATIVO</TitleGrid>
              <Grid style={{ width: "100%" }}>
                <Grid>
                  <Grid style={{ fontWeight: pallet.general.weight_600 }}>
                    EMENTA
                  </Grid>
                  <Grid style={{ wordBreak: "break-all" }}>
                    {search(props?.item.ementa)}
                  </Grid>
                </Grid>
                <Grid style={{ marginTop: "20px" }}>
                  <Grid style={{ fontWeight: pallet.general.weight_600 }}>
                    AUTOR
                  </Grid>
                  <Grid>
                    {props?.item.autores
                      ? props?.item.autores
                        .map((item) => {
                          return item.nome;
                        })
                        .join(", ")
                      : "Não informado"}
                  </Grid>
                </Grid>

                <ContainerGrid container style={{ marginTop: "20px" }}>
                  {(props?.item.sigla_casa === 'SF' || props?.item.sigla_casa === 'CD' || props?.item.sigla_casa === 'CN') ?
                    <ContentGrid item xs={4} lg={2} pallet={pallet}>
                      <Grid>SITUAÇÃO</Grid>
                      <Grid>
                        {props.item.situacao_descricao
                          ? props.item.situacao_descricao
                          : "Não informado"}
                      </Grid>
                    </ContentGrid>
                    : props.item.situacao_descricao ?


                      <ContentGrid item xs={4} lg={2} pallet={pallet}>
                        <Grid>SITUAÇÃO</Grid>
                        <Grid>
                          {props.item.situacao_descricao
                            ? props.item.situacao_descricao
                            : "Não informado"}
                        </Grid>
                      </ContentGrid>

                      :

                      null

                  }
                  {(props?.item.sigla_casa === 'SF' || props?.item.sigla_casa === 'CD' || props?.item.sigla_casa === 'CN') ?
                    <ContentGrid item xs={4} lg={2} pallet={pallet}>
                      <Grid>TEMA LEGISLATIVO</Grid>
                      <Grid>
                        {props.item.temas_legislativo.length
                          ? props.item.temas_legislativo
                            .map((item) => {
                              return item.descricao;
                            })
                            .join(", ")
                          : "Não informado"}
                      </Grid>
                    </ContentGrid>
                    : props.item.temas_legislativo.length ?


                      <ContentGrid item xs={4} lg={2} pallet={pallet}>
                        <Grid>TEMA LEGISLATIVO</Grid>
                        <Grid>
                          {props.item.temas_legislativo.length
                            ? props.item.temas_legislativo
                              .map((item) => {
                                return item.descricao;
                              })
                              .join(", ")
                            : "Não informado"}
                        </Grid>
                      </ContentGrid>

                      :

                      null
                  }
                  {(props?.item.sigla_casa === 'SF' || props?.item.sigla_casa === 'CD' || props?.item.sigla_casa === 'CN') ?

                    <ContentGrid item xs={4} lg={2} pallet={pallet}>
                      <Grid>TIPO</Grid>
                      <Grid>
                        {props.item.tipo_descricao
                          ? props.item.tipo_descricao
                          : "Não informado"}
                      </Grid>
                    </ContentGrid>

                    : props.item.tipo_descricao ?

                      <ContentGrid item xs={4} lg={2} pallet={pallet}>
                        <Grid>TIPO</Grid>
                        <Grid>
                          {props.item.tipo_descricao
                            ? props.item.tipo_descricao
                            : "Não informado"}
                        </Grid>
                      </ContentGrid>
                      :
                      null
                  }

                  {(props?.item.sigla_casa === 'SF' || props?.item.sigla_casa === 'CD' || props?.item.sigla_casa === 'CN') ?


                    <ContentGrid
                      item
                      xs={4}
                      lg={2}
                      style={{ marginTop: `${isMobile ? "10px" : "0px"}` }}
                      pallet={pallet}
                    >
                      <Grid>ORGÃO</Grid>
                      <Grid>
                        {props.item.orgao_sigla
                          ? props.item.orgao_sigla
                          : "Não informado"}
                      </Grid>
                    </ContentGrid>



                    : props.item.orgao_sigla ?
                      <ContentGrid
                        item
                        xs={4}
                        lg={2}
                        style={{ marginTop: `${isMobile ? "10px" : "0px"}` }}
                        pallet={pallet}
                      >
                        <Grid>ORGÃO</Grid>
                        <Grid>
                          {props.item.orgao_sigla
                            ? props.item.orgao_sigla
                            : "Não informado"}
                        </Grid>
                      </ContentGrid>

                      :

                      null
                  }


                  {(props?.item.sigla_casa === 'SF' || props?.item.sigla_casa === 'CD' || props?.item.sigla_casa === 'CN') ?
                    <ContentGrid item xs={12} lg={12} pallet={pallet} style={{ marginTop: `${isMobile ? "0px" : "15px"}` }}>
                      <Grid>TRAMITAÇÃO</Grid>
                      <Grid>
                        {props.item.tramitacao
                          ? props.item.tramitacao
                          : "Não informado"}
                      </Grid>
                    </ContentGrid>

                    : props.item.tramitacao ?

                      <ContentGrid item xs={12} lg={12} pallet={pallet} style={{ marginTop: `${isMobile ? "0px" : "15px"}` }}>
                        <Grid>TRAMITAÇÃO</Grid>
                        <Grid>
                          {props.item.tramitacao
                            ? props.item.tramitacao
                            : "Não informado"}
                        </Grid>
                      </ContentGrid>

                      :

                      null


                  }
                </ContainerGrid>
                {props.idPermissao !== 4 ? 
                <hr
                  style={{
                    border: "0px solid rgba(0, 0, 0, 0.12)",
                    borderBottomWidth: "thin",
                    margin: "20px 0",
                  }}
                />
                  :
                  null
                }


                <ContainerGrid container>
                  {props.idPermissao !== 4 ? <>
                <TitleGrid pallet={pallet}>CORPORATIVO</TitleGrid>
                  <ContentGrid item xs={3} lg={2} pallet={pallet}>
                    <Grid>GRUPO</Grid>
                    <Grid>
                      {props?.item?.cliente?.tema_corporativo?.grupo_descricao
                        ? props?.item?.cliente?.tema_corporativo
                          ?.grupo_descricao
                        : "Não informado"}
                    </Grid>
                  </ContentGrid>

                  <ContentGrid
                    item
                    xs={3}
                    lg={2}
                    pallet={pallet}
                    style={{ paddingLeft: `${isMobile ? "0px" : "21px"}` }}
                  >
                    <Grid>TEMA</Grid>
                    <Grid>
                      {props.item.cliente?.tema_corporativo?.tema_descricao
                        ? props.item?.cliente?.tema_corporativo?.tema_descricao
                        : "Não informado"}
                    </Grid>
                  </ContentGrid>

                  <ContentGrid
                    item
                    xs={3}
                    lg={2}
                    pallet={pallet}
                    style={{ paddingLeft: `${isMobile ? "0px" : "50px"}` }}
                  >
                    <Grid>SUBTEMA</Grid>
                    <Grid>
                      {props?.item.cliente?.tema_corporativo?.subtema_descricao
                        ? props?.item?.cliente?.tema_corporativo
                          ?.subtema_descricao
                        : "Não informado"}
                    </Grid>
                  </ContentGrid>

                  <ContentGrid
                    item
                    xs={3}
                    lg={2}
                    pallet={pallet}
                    style={{ paddingLeft: `${isMobile ? "0px" : "50px"}` }}
                  >
                    <Grid>TÓPICO</Grid>
                    <Grid>
                      {props?.item.cliente?.tema_corporativo?.subtema_descricao
                        ? props?.item?.cliente?.tema_corporativo
                          ?.topico_descricao
                        : "Não informado"}
                    </Grid>
                  </ContentGrid>
                  </>
                  :
                  null
                      }
                  {props.idPermissao !== 4 ? (
                    <ContentGrid
                      item
                      xs={3}
                      lg={2}
                      pallet={pallet}
                      style={{ paddingLeft: `${isMobile ? "0px" : "74px"}` }}
                    >
                      <Grid>PRIORIDADE</Grid>
                      <Grid style={{ width: "90px" }} container>
                        <DotStyle
                          bg={prioridadeColor(
                            props?.item.cliente.prioridade_descricao
                          )}
                        />
                        {props?.item.cliente.prioridade_descricao}
                      </Grid>
                    </ContentGrid>
                  ) : null}
                  {props.idPermissao !== 4 ? (
                    <ContentGrid
                      item
                      xs={12}
                      lg={6}
                      style={{
                        marginTop: `${isMobile ? "10px" : "20px"}`,
                        paddingLeft: `${isMobile ? "0px" : "0px"}`,
                        wordBreak: "break-all",
                      }}
                      pallet={pallet}
                    >
                      <Grid>POSICIONAMENTO</Grid>
                      <Grid container>
                        <Grid item>
                          <DotStyle
                            bg={posicionamentoColor(
                              props?.item.cliente.posicionamento_descricao
                            )}
                          />
                        </Grid>
                        <Grid item>
                          {props?.item.cliente.posicionamento_descricao}
                        </Grid>
                      </Grid>
                    </ContentGrid>
                  ) : null}
                </ContainerGrid>
              </Grid>
            </Grid>

            <Grid>
              {/* <AccordionProposicaoListaDetail listadetail={props?.item.acoes} /> */}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <RightButton
          style={{
            position: "absolute",
            right: isMobile ? "65px" : "20px",
            top: isMobile ? "140px" : "3px",
          }}
        >
          {props.idPermissao !== 4 ? (
            <ButtonNaoSeguir
              id={props.item.id}
              status={props.item.cliente.seguindo}
              onClick={() =>
                props.idPermissao !== 3
                  ? seguirClick(props.item.id, props.item.cliente.seguindo)
                  : NewAlert(
                    "error",
                    "Não é possível realizar está ação com este perfil."
                  )
              }
              width={"140px"}
            />
          ) : null}

          {props.idPermissao !== 4 ? (
            <ButtonSeguir
              id={props.item.id}
              status={props.item.cliente.seguindo}
              onClick={() =>
                props.idPermissao !== 3
                  ? seguirClick(props.item.id, props.item.cliente.seguindo)
                  : NewAlert(
                    "error",
                    "Não é possível realizar está ação com este perfil."
                  )
              }
              width={"110px"}
            />
          ) : null}
        </RightButton>
      </Grid>
    </>
  );
};

export default AccordionProposicaoLista;
