import Paper from "@mui/material/Paper";
import React from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import TabPanel from "../../../../components/carousel/TabPanel";
import { usePallet } from "../../../../contexts/PalletContext";
import ProposicaoAcaoInfluenciaNotaTecnicaService from "../../../../services/ProposicaoAcaoPreparatoriaNotaTecnicaService";
import ProposicaoAcaoPreparatoriaEspecificaNotaTecnica from "./ProposicaoAcaoPreparatoriaEspecificaNotaTecnica";
import { IAcaoPreparatoria } from "../../../../models/Proposicao.Model";

const ProposicaoAcaoPreparatoriaEspecificaCarrouselTabsNotaTecnica = (props: {
  activeTab: any;
  index: number;
  acao: IAcaoPreparatoria;
  onChange: (data: any) => void;
  reload?: () => void;
}) => {
  const { pallet } = usePallet();
  const [notaTecnica, setNotaTecnica] = React.useState<any>();

  return (
    <TabPanel value={props.activeTab} index={props.index}>
      <Paper
        style={{
          borderRadius: "6px",
          marginTop: `${isMobile ? "0px" : "0px"}`,
        }}
      >
        <ProposicaoAcaoPreparatoriaEspecificaNotaTecnica
          acao={props.acao}
          lista={props.acao?.notaTecnica?.lista}
          onChange={props.onChange}
          reload={() => {
            props?.reload();
          }}
        />
      </Paper>
    </TabPanel>
  );
};

export default ProposicaoAcaoPreparatoriaEspecificaCarrouselTabsNotaTecnica;
